/* global window document */
import { useFela, } from 'react-fela';
import { useApolloClient, } from '@apollo/react-hooks';
import { SessionListener, } from '@haaretz/htz-user-utils';

import { GET_MARKETING_GROUPS_AND_VISITS_QUERY, } from '@haaretz/graphql';
import React from 'react';
import { useRouter, } from 'next/router';
import PAGE_DATA_QUERY from '../gql/getPageData.graphql';
import USER_DATA_QUERY from '../gql/getUserData.graphql';
import ARTICLE_AUTHORS_QUERY from '../gql/getArticleAuthors.graphql';

import { useUser, } from '../../User/UserDispenser';
import useMarketingToolsStats from './useRainbowToolsStats';
import checkSiteFromConfig from '../../../utils/checkSiteFromConfig';
import usePaywallType from '../../../hooks/Page/usePaywallType';


const getLiveBlogQueryVariables = (url, liveBlogItemId, articleId) => {
  const regex = /\/ty-article-live\//;
  const containsTyArticleLive = regex.test(url);

  const queryVariables = {
    model: { id: articleId, },
  };

  if (containsTyArticleLive) {
    queryVariables.liveBlogItemsCount = liveBlogItemId ? 1 : 10;
    if (liveBlogItemId) {
      queryVariables.liveBlogItemId = liveBlogItemId;
    }
  }

  return queryVariables;
};


/**
 * Hook for collecting facts for Rainbow
 * @returns A function that collects facts for Rainbow
 */
export default function useFactsProvider() {
  const apolloClient = useApolloClient();
  const { user, } = useUser();
  const { theme, } = useFela();
  const marketingToolsStats = useMarketingToolsStats({ user, });
  const { paywallType, } = usePaywallType();
  const router = useRouter();

  return React.useCallback(async () => {
    // collect data on users current-platform
    const platform = discoverUserPlatform(theme);

    // Collect data on page (pageType)
    const pageData = discoverPageType({ apolloClient, });

    // Get info about user: products and status
    const userInfoRequest = queryUserInfo({ apolloClient, user, });

    // Get marketing-groups
    const personalizedCampaignsRequest = queryPersonalizedCampaigns({ apolloClient, });

    // Get local info about markeing-tools the user has seen.
    const marketingToolsStatisticsRequest = marketingToolsStats.readValues();

    // Get session details
    const sessionDetails = getSessionDetails();

    const articleAuthors = queryArticleAuthors({ apolloClient, router, });

    // Request for marketing-tools for the user.
    return Promise
      .all([ platform, pageData, userInfoRequest, personalizedCampaignsRequest, marketingToolsStatisticsRequest, sessionDetails, articleAuthors, ])
      .then(([ platformRes, pageDataRes, userInfoRes, personalizedCampaignsRes, marketingToolsStatisticsRes, sessiosDetailsRes, articleAuthorsRes, ]) => Promise.resolve({
        platform: platformRes,
        pagePaywallLevel: paywallType,
        referrerUrl: document.location.href,
        pageType: pageDataRes?.pageType,
        userType: user?.type,
        authors: articleAuthorsRes,
        user: userInfoRes && userInfoRes?.user,
        personalizedCampaigns: personalizedCampaignsRes?.personalizedCampaigns,
        purchaseVisit: personalizedCampaignsRes?.purchaseVisits,
        marketingToolsStats: marketingToolsStatisticsRes,
        sessionDetails: sessiosDetailsRes,
        testGroups: collectTestGroups(marketingToolsStatisticsRes),
      }));
  }, [ apolloClient, marketingToolsStats, paywallType, router, theme, user, ]);
}

function collectTestGroups(toolsStats) {
  if (!toolsStats || toolsStats.length === 0) {
    return null;
  }

  const groups = toolsStats
    .map(stats => stats.testGroup)
    .filter(group => !!group)
    .reduce((acc, item) => {
      const [ key, val, ] = item.split(':');
      acc[key] = val;

      return acc;
    }, {});

  return groups;
}

/**
 * Find current page type
 * @param { Object } settings
 * @retrun {String} one of [ homepage | section | closedArticle | openArticle ]
 */
export async function discoverPageType({ apolloClient, }) {
  const { data, } = await apolloClient.query({
    query: PAGE_DATA_QUERY,
  });

  //
  if (data.pageType.includes('Article')) {
    data.pageType = data.isBlocked ? 'closedArticle' : 'openArticle';
  }

  return data;
}

/**
 * Get Authors ids on article page
 * @param {Object} settings
 * @returns On article-page returns array of authors ids. on other pages returns null.
 */
export async function queryArticleAuthors({ apolloClient, router, }) {
  const pageData = await discoverPageType({ apolloClient, });
  let authors = null;

  const { asPath, query, } = router || {};
  const liveBlogItemId = query?.liveBlogItemId;

  if (pageData.pageType.includes('Article')) {
    const { articleId, } = pageData;

    const queryVariables = getLiveBlogQueryVariables(asPath, liveBlogItemId, articleId);

    const { data, } = await apolloClient.query({
      query: ARTICLE_AUTHORS_QUERY,
      variables: queryVariables,
    });

    authors = (data?.Page?.slots?.article?.authors || []).map(author => author.contentId);
  }

  return authors;
}

export async function discoverUserPlatform(theme) {
  const mqStr = theme.getMqString({ until: 's', }, true);
  const mq = window.matchMedia(mqStr);
  return Promise.resolve(mq.matches ? 'mobile' : 'desktop');
}

/**
 * Find marketing groups that current user is targeted with
 * @param { Object } settings
 * @returns Array of group-names targeted for current user
 */
export async function queryPersonalizedCampaigns({ apolloClient, }) {
  const { data, } = await apolloClient.query({
    query: GET_MARKETING_GROUPS_AND_VISITS_QUERY,
  }).catch(error => Promise.resolve({ data: null, }));

  const res = {
    personalizedCampaigns: data?.personalizedCampaigns?.length ? data.personalizedCampaigns : null,
    purchaseVisits: data?.purchaseVisits?.date ? data?.purchaseVisits : null,
  };

  return res;
}

/**
 * Get the user info ( name, products, email-validation status, termsCheck status)
 * @param { Object } settings
 * @returns the user-info
 */
export async function queryUserInfo({ apolloClient, user, }) {
  const userType = user.type;
  let userData = null;
  const { siteAlias, } = checkSiteFromConfig();

  if (userType && userType !== 'anonymous' && apolloClient != null) {
    const { data, } = await apolloClient.query({
      query: USER_DATA_QUERY,
      variables: {
        site: siteAlias.toUpperCase(),
      },
    });

    userData = { user: data.userData, };
  }
  else if (userType === 'anonymous') {
    userData = { user, };
  }
  else if (!userData) {
    userData = { user: null, };
  }

  return userData;
}

/**
 * Collect session info
 * @returns session-info from browsers session-storage
 */
export async function getSessionDetails() {
  return Promise.resolve(SessionListener.sessionDetails);
}
