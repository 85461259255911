// @flow
/* eslint-disable react/prop-types */
/* global window, fetch, Headers, document  */
import React from 'react';
import dynamic from 'next/dynamic';
import type { StatelessFunctionalComponent, Node, } from 'react';
import ReactGA from 'react-ga';

import { FelaComponent, FelaTheme, useFela, } from 'react-fela';

import { COMMENTS_COUNT, } from '@haaretz/graphql';
import { useQuery, } from 'react-apollo';
import type {
  CommentButtonProps,
  FacebookButtonProps,
  FacebookLogoProps,
  // FacebookLogoState,
  GooglePlusButtonProps,
  MailAlertButtonProps,
  MailButtonProps,
  MessengerButtonProps,
  PrintButtonProps,
  RedditButtonProps,
  TwitterButtonProps,
  WhatsappButtonProps,
  ZenButtonProps,
} from './types';

import UserDispenser from '../User/UserDispenser';
import Mutation from '../ApolloBoundary/Mutation';
import Query from '../ApolloBoundary/Query';
import Save from './ActionSaveToReadingList/ActionSaveToReadingList';
import GiftAction from './action/gift/GiftAction';
import IconComment from '../Icon/icons/IconComment';
import IconFacebook from '../Icon/icons/IconFacebook';
import IconFacebookLogo from '../Icon/icons/IconFacebookLogo';
import IconGPlus from '../Icon/icons/IconGPlus';
import IconMail from '../Icon/icons/IconMail';
import IconMailAlert from '../Icon/icons/IconMailAlert';
import IconMessenger from '../Icon/icons/IconMessenger';
import IconPrint from '../Icon/icons/IconPrint';
import IconReading from '../Icon/icons/IconReading';
import IconReddit from '../Icon/icons/IconReddit';
import IconTwitter from '../Icon/icons/IconTwitter';
import IconTwitterRound from '../Icon/icons/IconTwitterRound';
import IconTelegram from '../Icon/icons/IconTelegram';
import IconWhatsapp from '../Icon/icons/IconWhatsapp';
import IconZen from '../Icon/icons/IconZen';
import VisuallyHidden from '../VisuallyHidden/VisuallyHidden';
import A11yDialog from '../A11yDialog/A11yDialog';
import ActionButton from './ActionButton';
import Button from './Button';
import Media from '../Media/Media';
import GET_TWITTER_TITLE from './queries/twitterTitle.graphql';
import TOGGLE_ZEN from './queries/toggleZen.graphql';
import useWebViewChecker from '../../hooks/useWebViewChecker';
import useArticleData from '../../hooks/Page/useArticleData';
import useIsLabel from '../../hooks/Page/useIsLabel';

function popup(url, width, height) {
  window.open(
    url,
    'popup',
    `width=${width},height=${height},scrollbars=no,resizable=no,toolbar=no,directories=no,location=no,menubar=no,status=no`
  );
}

const fbAppIds: Object = new Map([
  [ 'haaretz.co.il', '110687712359084', ],
  [ 'themarker.com', '52929925921', ],
  [ 'haaretz.com', '287530407927859', ],
]);

const Comments: StatelessFunctionalComponent<CommentButtonProps> = ({
  buttonStyles,
  textMiscStyles = {},
  size,
  iconStyles,
  // destructuring next couple of props to avoid passing them down to the DOM element
  // (the generic ActionButtons always passes down these props)
  ...props
}): Node => {
  const { theme, css, } = useFela();
  const articleData = useArticleData();

  const { commentsElementId, } = articleData || {};

  const { loading, error, data, } = useQuery(COMMENTS_COUNT, {
    variables: {
      model: { id: commentsElementId, },
    },
    ssr: false,
    skip: !commentsElementId,
  });

  if (loading) return null;
  if (error) return null;
  if (!data || typeof data.CommentsElement?.totalHits !== 'number') return null;

  const commentsNumber: number = data.CommentsElement.totalHits;

  return (
    <ActionButton
      render={({ platform, biAction, biActionMapper, }) => (
        <Button
          {...props}
          data-test="commentsButton"
          miscStyles={buttonStyles}
          title={theme.shareBarI18n.commentsPlusTooltip}
          href="#commentsSection"
          onClick={() => {
            biAction({
              actionCode: biActionMapper.get('go_to_comments'),
              feature: 'Article Page - Skip to Comments',
              featureType: 'Content',
            });
          }}
        >
          {commentsNumber > 0 ? (
            <span
              className={css({
                marginEnd: '1rem',
                paddingBlockStart: '0.3rem',
                color: theme.color('neutral', '-3'),
                extend: [ textMiscStyles, ],
              })}
            >
              {commentsNumber}
            </span>
          ) : null}
          <IconComment size={size} miscStyles={iconStyles} />
        </Button>
      )}
    />
  );
};

const Facebook = ({
  buttonStyles,
  buttonText,
  clickAreaSize,
  size,
  iconStyles,
  elementUrl,
  redirectUri,
  round,
  // destructuring next couple of props to avoid passing them down to the DOM element
  // (the generic ActionButtons always passes down these props)
  textMiscStyles,
  elementName,
  ...props
}: FacebookButtonProps): Node => {
  const FacebookIcon = round ? IconFacebook : IconFacebookLogo;
  const { theme, } = useFela();
  const isWebView = useWebViewChecker();

  return (
    <ActionButton
      render={({ platform, biAction, biActionMapper, host, }) => (
        <Button
          {...props}
          size={clickAreaSize}
          miscStyles={buttonStyles}
          title={theme.shareBarI18n.facebookToolTip}
          target="_blank"
          onClick={() => {
            if (!isWebView) {
              // TODO: remove facebook sharer when hdc facebook app id valid again.
              host.includes('haaretz.com') ? popup(`https://www.facebook.com/sharer/sharer.php?&u=${elementUrl}`, 635, 342) : popup('https://www.facebook.com/dialog/feed'
              + `?app_id=${fbAppIds.get(host)}`
              + `&redirect_uri=${redirectUri ? `https://${redirectUri}` : elementUrl}`
              + `&link=${elementUrl}`
              + '&display=popup', 635, 342);


              biAction({
                actionCode: biActionMapper.get('facebook_share'),
              });
            }
          }}
        >
          {buttonText ? (
            <FelaComponent style={{ marginEnd: '1rem', }} as="span">
              {buttonText}
            </FelaComponent>
          ) : null}
          <FacebookIcon size={size} miscStyles={iconStyles} />
        </Button>
      )}
    />
  );
};
Facebook.defaultProps = { buttonText: null, };

const FacebookItemShare: StatelessFunctionalComponent<FacebookButtonProps> = ({
  buttonStyles,
  buttonText,
  clickAreaSize,
  size,
  iconStyles,
  elementUrl,
  redirectUri,
  round,
  ...props
}): Node => {
  const FacebookIcon = round || props.facebookRound ? IconFacebook : IconFacebookLogo;
  const { theme, } = useFela();

  return (
    <ActionButton
      render={({ platform, biAction, biActionMapper, host, }) => (
        <Button
          {...props}
          size={clickAreaSize}
          miscStyles={buttonStyles}
          title={theme.shareBarI18n.facebookToolTip}
          // href={`https://www.facebook.com/dialog/feed?app_id=${fbAppIds.get(
          //   host
          // )}&redirect_uri=${elementUrl}&link=${elementUrl}&display=popup`}
          target="_blank"
          onClick={evt => {
            evt.preventDefault();
            window.open(
              `https://www.facebook.com/dialog/feed?app_id=${fbAppIds.get(
                host
              )}&redirect_uri=${
                redirectUri ? `https://${redirectUri}` : elementUrl
              }&link=${elementUrl}&display=popup`,
              'popup',
              'width=635,height=342,scrollbars=no,resizable=no,toolbar=no,directories=no,location=no,menubar=no,status=no'
            );
            biAction({
              actionCode: biActionMapper.get('facebook_share'),
            });
          }}
        >
          {buttonText ? (
            <FelaComponent style={{ marginEnd: '1rem', }} as="span">
              {buttonText}
            </FelaComponent>
          ) : null}
          <FacebookIcon size={size} miscStyles={iconStyles} />
        </Button>
      )}
    />
  );
};

const FacebookRound = (props: Object) => <Facebook {...props} round />;

function FacebookLogo({
  buttonStyles,
  size,
  iconStyles,
  elementUrl,
  clickAreaSize,
  // destructuring next couple of props to avoid passing them down to the DOM element
  // (the generic ActionButtons always passes down these props)
  textMiscStyles,
  elementName,
  authorFacebook,
  ...props
}: FacebookLogoProps) {
  const [ facebookCount, setFacebookCount, ] = React.useState(null);
  const { css, theme, } = useFela();

  React.useEffect(() => {
    const getFacebookCount = async () => {
      const url: string = `https://graph.facebook.com/?id=${elementUrl}&fields=og_object{engagement}`;

      try {
        const response = await fetch(url);
        const data = await response.json();
        if (data && data.og_object && data.og_object.engagement) {
          setFacebookCount(data.og_object.engagement.count);
        }
      }
      catch (err) {
        console.log('error from FacebookLogo action button', err);
      }
    };
    getFacebookCount();
  }, [ elementUrl, ]);

  return (
    <ActionButton
      render={({ platform, biAction, biActionMapper, host, }) => (
        <Button
          data-test="facebookButton"
          {...props}
          size={clickAreaSize}
          miscStyles={{
            ...buttonStyles,
            direction: 'ltr',
            display: 'flex',
            alignItems: 'center',
          }}
          title={authorFacebook ? theme.shareBarI18n.authorFacebookToolTip : theme.shareBarI18n.facebookToolTip}
          target="_blank"
          onClick={() => {
            authorFacebook
              ? window.open(authorFacebook)
              : popup(`https://www.facebook.com/sharer/sharer.php?&u=${elementUrl}`, 635, 342);
            biAction({
              actionCode: biActionMapper.get('facebook_share'),
              additionalInfo: {
                platform,
                ...(facebookCount ? { NumOfTalkbacks: facebookCount, } : {}),
              },
            });
          }}
        >
          <IconFacebookLogo size={size} miscStyles={iconStyles} />
          {theme.articleStyle.header.showFacebookEngagementCount
          && facebookCount ? (
            <span
              className={css({
                color: theme.color('primary'),
                marginInlineStart: '0.5rem',
              })}
            >
              {facebookCount}
            </span>
            ) : null}
        </Button>
      )}
    />
  );
}

const GooglePlus = ({
  buttonStyles,
  buttonText,
  size,
  iconStyles,
  elementUrl,
  ...props
}: GooglePlusButtonProps): Node => {
  const { theme, } = useFela();

  return (
    <ActionButton
      render={({ platform, biAction, biActionMapper, }) => (
        <Button
          {...props}
          data-test="googleplusButton"
          miscStyles={buttonStyles}
          title={theme.shareBarI18n.googlePlusToolTip}
          href={`https://plus.google.com/share?url=${elementUrl}`}
          onClick={() => {
            biAction({
              actionCode: biActionMapper.get('other_share'),
            });
          }}
        >
          {buttonText ? (
            <FelaComponent style={{ marginEnd: '1rem', }} as="span">
              {buttonText}
            </FelaComponent>
          ) : null}
          <IconGPlus size={size} miscStyles={iconStyles} />
        </Button>
      )}
    />
  );
};
GooglePlus.defaultProps = { buttonText: null, };

const Mail: StatelessFunctionalComponent<MailButtonProps> = ({
  buttonStyles,
  size,
  clickAreaSize,
  iconStyles,
  elementName,
  elementUrl,
  // destructuring next prop to avoid passing it down to the DOM element
  // (the generic ActionButtons always passes down this prop)
  textMiscStyles,
  authorEmail,
  ...props
}): Node => {
  const { theme, } = useFela();
  return (
    <ActionButton
      render={({ platform, biAction, biActionMapper, }) => (
        <Button
          {...props}
          data-test="mailButton"
          size={clickAreaSize}
          miscStyles={buttonStyles}
          title={authorEmail ? theme.shareBarI18n.authorEmailToolTip : theme.shareBarI18n.emailToolTip}
          href={authorEmail ? `mailto:${authorEmail}`
            : `mailto:?subject=${elementName}${theme.shareBarI18n.emailSubjectSuffix}&body=${elementUrl}`}
          onClick={() => {
            biAction({
              actionCode: biActionMapper.get('mail_share'),
              feature: 'Article Page - Share Button',
              featureType: 'Content',
            });
          }}
        >
          <VisuallyHidden>{authorEmail ? theme.shareBarI18n.authorEmailToolTip : theme.shareBarI18n.emailToolTip}</VisuallyHidden>
          <IconMail size={size} miscStyles={iconStyles} />
        </Button>
      )}
    />
  );
};

const MailAlert: StatelessFunctionalComponent<MailAlertButtonProps> = ({
  buttonStyles,
  size,
  clickAreaSize,
  iconStyles,
  elementName,
  elementUrl,
  ...props
}): Node => (
  <ActionButton
    render={({ platform, biAction, biActionMapper, }) => (
      <Button
        {...props}
        size={clickAreaSize}
        miscStyles={buttonStyles}
        href={`mailto:?subject=${elementName}&body=${elementUrl}`}
        onClick={() => {
          biAction({
            actionCode: biActionMapper.get('author_alert'),
          });
        }}
      >
        <IconMailAlert size={size} miscStyles={iconStyles} />
      </Button>
    )}
  />
);

const Messenger = ({
  buttonStyles,
  buttonText,
  size,
  iconStyles,
  elementUrl,
  ...props
}: MessengerButtonProps): Node => {
  const { theme, } = useFela();

  return (
    <ActionButton
      render={({ platform, biAction, host, biActionMapper, }) => (
        <Button
          {...props}
          data-test="messengerButton"
          miscStyles={buttonStyles}
          title={theme.shareBarI18n.fbMessengerToolTip}
          href={`fb-messenger://share/?link=${elementUrl}&app_id=${fbAppIds.get(
            host
          )}`}
          onClick={() => {
            biAction({
              actionCode: biActionMapper.get('messenger_share'),
            });
          }}
        >
          {buttonText ? (
            <FelaComponent style={{ marginEnd: '1rem', }} as="span">
              {buttonText}
            </FelaComponent>
          ) : null}
          <IconMessenger size={size} miscStyles={iconStyles} />
        </Button>
      )}
    />
  );
};
Messenger.defaultProps = { buttonText: null, };

function getUpdatedQueryParam(key, value) {
  const urlParams = new URLSearchParams(document.location.search.substr(1));
  urlParams.set(key, value);
  return urlParams.toString();
}

const Print: StatelessFunctionalComponent<PrintButtonProps> = ({
  buttonStyles,
  size,
  iconStyles,
  // destructuring next couple of props to avoid passing them down to the DOM element
  // (the generic ActionButtons always passes down these props)
  textMiscStyles,
  elementName,
  elementUrl,
  ...props
}): Node => {
  const { theme, } = useFela();
  const [ showModal, setShowModal, ] = React.useState();
  const isLabel = useIsLabel();

  return (
    <UserDispenser
      render={({ user, }) => (
        <ActionButton
          render={({ platform, biAction, biActionMapper, articleId, hostname, biImpression, }) => (
            <>
              <Button
                attrs={{
                  target: '_blank',
                  tabIndex: '-1',
                }}
                data-test="printArticleButton"
                tabIndex="-1"
                {...props}
                title={theme.shareBarI18n.printToolTip}
                miscStyles={buttonStyles}
                onClick={() => {
                  const isPayingUser = user && user.type === 'paying';
                  if (isPayingUser || isLabel) {
                    // Not using a relative path, as it somehow doen't
                    // work as expected in this code when inside proxy hosts
                    // const printUrlProtocol = window.location.protocol === 'http:' ? 'http:' : 'https:';
                    // const printUrl = `${printUrlProtocol}//${window.location.hostname}/misc/article-print-page/${articleId}`;
                    // popup(printUrl, window.screen.width, window.screen.height);

                    const printUrl = `${document.location.origin}${document.location.pathname}?${getUpdatedQueryParam('print', true)}`;
                    popup(printUrl, window.screen.width, window.screen.height);

                    // window.print();

                    biAction({
                      actionCode: biActionMapper.get('print'),
                    });
                  }
                  else {
                    biAction({
                      actionCode: 112,
                      featureType: 'Marketing',
                      feature: 'Printer Friendly Popup',
                      campaignName: 'Printer Friendly',
                      campaignDetails: 'icon',
                    });
                    biImpression({
                      featureType: 'Marketing',
                      feature: 'Printer Friendly Popup',
                      campaignName: 'Printer Friendly',
                      campaignDetails: theme.printNonPayingUsers && theme.printNonPayingUsers.button,
                    });
                    setShowModal(true);
                  }
                }}
              >
                <IconPrint size={size} miscStyles={iconStyles} />
              </Button>
              <A11yDialog
                appendTo="share_bar_modal"
                overlayBgColor={theme.shareBarPopup.overlayBgColor}
                elementToHide="pageRoot"
                isVisible={showModal}
                containerMiscStyles={{ outline: 'none', }}
                closeOnOutsideClick
                onClose={() => setShowModal(false)}
                isModal
                render={({ isVisible, handleClose, }) => (
                  <FelaComponent
                    style={{
                      paddingInlineEnd: '8rem',
                      paddingInlineStart: '6rem',
                      paddingBottom: '4rem',
                      outline: 'none',
                    }}
                  >
                    <ShareBarAstronaut isPrint onClose={handleClose} biAction={biAction} />
                  </FelaComponent>
                )}
              />
            </>
          )}
        />
      )}
    />
  );
};


const ReadingList: StatelessFunctionalComponent<CommentButtonProps> = ({
  buttonStyles,
  size,
  iconStyles,
  ...props
}): Node => (
  <ActionButton
    render={({ platform, biAction, hostname, }) => (
      <Button
        {...props}
        data-test="readinglistButton"
        miscStyles={buttonStyles}
        title="רשימת קריאה"
        // TODO: check if this is being used - haaretz reading list URL has changed
        href={`http://${hostname}/personal-area/my-account#readingList`}
        onClick={() => {
          biAction({
            actionCode: 240,
          });
        }}
      >
        <IconReading size={size} miscStyles={iconStyles} isFilled />
        <FelaComponent
          style={({ theme, }) => ({
            color: theme.color('neutral', '-2'),
            extend: [ theme.type(-2), ],
          })}
          render={({ className, }) => (
            <span className={className}>רשימת קריאה</span>
          )}
        />
      </Button>
    )}
  />
);
// Save component is set from ActionSave.js

const Twitter = ({
  buttonStyles,
  size,
  iconStyles,
  elementName,
  elementUrl,
  round,
  // Destructured so it would not get passed down to Button
  textMiscStyles,
  authorTwitter,
  ...props
}: TwitterButtonProps): Node => {
  const { theme, } = useFela();
  const Icon = round ? IconTwitterRound : IconTwitter;
  const isWebView = useWebViewChecker();

  return (
    <ActionButton
      render={({ platform, biAction, biActionMapper, }) => (
        <Query query={GET_TWITTER_TITLE}>
          {({ data, error, loading, }) => {
            const title = (data && data.twitterTitle) || elementName;
            return (
              <Button
                {...props}
                data-test="twitterButton"
                miscStyles={buttonStyles}
                title={authorTwitter ? theme.shareBarI18n.authorTwitterToolTip : theme.shareBarI18n.twitterToolTip}
                target="_blank"
                onClick={() => {
                  if (!isWebView) {
                    authorTwitter
                      ? window.open(authorTwitter)
                      : popup(`https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=${encodeURIComponent(elementUrl)}`, 635, 450);
                    biAction({
                      actionCode: biActionMapper.get('twitter_share'),
                    });
                  }
                }}
              >
                <Icon size={size} miscStyles={iconStyles} />
              </Button>
            );
          }}
        </Query>
      )}
    />
  );
};
Twitter.defaultProps = { round: false, };

const Reddit: StatelessFunctionalComponent<RedditButtonProps> = ({
  buttonStyles,
  size,
  clickAreaSize,
  iconStyles,
  elementName,
  elementUrl,
  // Destructured so it would not get passed down to Button
  textMiscStyles,
  ...props
}): Node => {
  const { theme, } = useFela();

  return (
    <ActionButton
      render={({ platform, biAction, }) => (
        <Button
          {...props}
          data-test="redditButton"
          size={clickAreaSize}
          miscStyles={buttonStyles}
          title={theme.shareBarI18n.redditToolTip}
          target="_blank"
          onClick={() => {
            popup(`https://www.reddit.com/submit?url=${elementUrl}&title=${elementName}`, 635, 550);
            biAction({
              actionCode: 139,
              additionalInfo: {
                platform,
              },
            });
          }}
        >
          <IconReddit size={size} miscStyles={iconStyles} />
        </Button>
      )}
    />
  );
};

const Whatsapp = ({
  buttonStyles,
  size,
  iconStyles,
  elementUrl,
  clickAreaSize,
  shouldMainNavBarDisplay,
  // destructuring next couple of props to avoid passing them down to the DOM element
  // (the generic ActionButtons always passes down these props)
  textMiscStyles,
  elementName,
  ...props
}: WhatsappButtonProps): Node => {
  const isWebView = useWebViewChecker();

  return (
    <ActionButton
      render={({ platform, biAction, biActionMapper, }) => (
        <FelaTheme>
          {theme => (
            <Media query={{ until: 'm', }}>
              {matches => (
                <Button
                  {...props}
                  data-test="whatsappButton"
                  size={clickAreaSize}
                  miscStyles={buttonStyles}
                  title={theme.shareBarI18n.whatsappToolTip}
                  onClick={() => {
                    if (!isWebView) {
                      const url = `${platform === 'mobile'
                        ? 'whatsapp://'
                        : 'https://web.whatsapp.com/'
                      }send?text=${elementUrl}${encodeURIComponent(
                        '?utm_source=Web_Share&utm_medium=Whatsapp&utm_campaign=Share'
                      )}`;

                      biAction({
                        featureType: 'Content',
                        feature: 'Article Page - Whatsapp Share Button',
                        actionCode: biActionMapper.get('whatsApp_share'),
                      });

                      if (platform === 'mobile') {
                        window.location = url;
                      }
                      else {
                        window.open(
                          url,
                          'popup',
                          'width=635,height=800,scrollbars=no,resizable=no,toolbar=no,directories=no,location=no,menubar=no,status=no'
                        );
                      }
                    }

                    return false;
                  }}
                >
                  <IconWhatsapp size={size} miscStyles={iconStyles} />
                </Button>
              )}
            </Media>
          )}
        </FelaTheme>
      )}
    />
  );
};

const Telegram = ({
  buttonStyles,
  size,
  iconStyles,
  elementUrl,
  clickAreaSize,
  shouldMainNavBarDisplay,
  // destructuring next couple of props to avoid passing them down to the DOM element
  // (the generic ActionButtons always passes down these props)
  textMiscStyles,
  elementName,
  ...props
}: WhatsappButtonProps): Node => (
  <ActionButton
    render={({ platform, biAction, biActionMapper, }) => (
      <FelaTheme>
        {theme => (
          // <Tooltip
          //   text={theme.shareBarI18n.whatsappTooltip}
          //   storageParam="tooltipCount"
          //   tooltipMiscStyles={{
          //     minWidth: [ { until: 's', value: '33rem', }, ],
          //     whiteSpace: [ { from: 's', value: 'nowrap', }, ],
          //   }}
          //   openSide="top"
          //   offsetX={matches ? 0 : -15}
          //   hide={shouldMainNavBarDisplay === false}
          // >
          <Button
            {...props}
            data-test="telegramButton"
            size={clickAreaSize}
            miscStyles={buttonStyles}
            title={theme.shareBarI18n.telegramToolTip}
            onClick={() => {
              const url = `https://telegram.me/share/url?url=${elementUrl}`;

              biAction({
                actionCode: biActionMapper.get('telegram_share'),
              });

              if (platform === 'mobile') {
                window.location = url;
              }
              else {
                window.open(
                  url,
                  'popup',
                  'width=635,height=800,scrollbars=no,resizable=no,toolbar=no,directories=no,location=no,menubar=no,status=no'
                );
              }

              return false;
            }}
          >
            <IconTelegram size={size} miscStyles={iconStyles} />
          </Button>
          // </Tooltip>
        )}
      </FelaTheme>
    )}
  />
);

const ShareBarAstronaut = dynamic(() => import('../ShareBar/ShareBarAstronaut'), {
  loading: () => null,
  ssr: false,
});

function Zen({
  buttonStyles,
  size,
  buttonText,
  iconStyles,
  elementUrl,
  // destructuring next couple of props to avoid passing them down to the DOM element
  // (the generic ActionButtons always passes down these props)
  textMiscStyles,
  elementName,
  ...props
}: ZenButtonProps): Node {
  const [ showModal, setShowModal, ] = React.useState();
  const { theme, css, } = useFela();
  return (
    <UserDispenser
      render={({ user, }) => (
        <Mutation mutation={TOGGLE_ZEN}>
          {toggleZen => (
            <ActionButton
              render={({ platform, biAction, biImpression, biActionMapper, zenMode, }) => (
                <>
                  <Button
                    data-test="zenModeButton"
                    {...props}
                    miscStyles={{
                      ...(buttonStyles || {}),
                      direction: 'ltr',
                      display: 'flex',
                      ...(!zenMode
                        ? {}
                        : {
                          color: theme.color('secondary'),
                        }),
                    }}
                    onClick={() => {
                      const isPayingUser = user && user.type === 'paying';
                      if (isPayingUser) {
                        biAction({
                          actionCode: 92,
                          feature: 'Zen_button',
                          featureType: 'Content',
                          campaignName: 'Zen',
                        });
                        toggleZen();
                      }
                      else {
                        ReactGA.ga('ec:addPromo', {
                          name: 'Article Page - Zen Button – Buy now button',
                          id: 'article-page-zen-button',
                          position: 'Article Page Zen button',
                        }) && ReactGA.ga('send', 'pageview');

                        biAction({
                          actionCode: biActionMapper.get('zen_mode'),
                          feature: 'Zen_button',
                          featureType: 'Content',
                          campaignName: 'Zen',
                        });
                        biImpression({
                          // actionCode: 3,
                          feature: 'Zen',
                          featureType: 'Content',
                          campaignName: 'Zen',
                          campaignDetails: theme.zenNonPayingUsers && theme.zenNonPayingUsers.button,
                        });
                        setShowModal(true);
                      }
                    }}
                  >
                    <IconZen size={size} miscStyles={iconStyles} />
                    <span
                      className={css({
                        marginStart: '0.5rem',
                        paddingBlockStart: '0.3rem',
                        extend: [ textMiscStyles, ],
                      })}
                    >
                      {theme.zenTextI18n}
                    </span>
                  </Button>
                  <A11yDialog
                    appendTo="share_bar_modal"
                    overlayBgColor={theme.shareBarPopup.overlayBgColor}
                    elementToHide="pageRoot"
                    isVisible={showModal}
                    containerMiscStyles={{ outline: 'none', }}
                    closeOnOutsideClick
                    onClose={() => setShowModal(false)}
                    isModal
                    render={({ isVisible, handleClose, }) => (
                      <FelaComponent
                        style={{
                          paddingInlineEnd: '8rem',
                          paddingInlineStart: '6rem',
                          paddingBottom: '4rem',
                          outline: 'none',
                        }}
                      >
                        <ShareBarAstronaut onClose={handleClose} biAction={biAction} />
                      </FelaComponent>
                    )}
                  />
                </>
              )}
            />
          )}
        </Mutation>
      )}
    />
  );
}
Zen.defaultProps = { buttonText: null, };

const getAction = (iconName: string) => {
  const actions = new Map([
    [ 'comments', Comments, ],
    [ 'facebook', Facebook, ],
    [ 'facebooklogo', FacebookLogo, ],
    [ 'facebookitemshare', FacebookItemShare, ],
    [ 'facebookround', FacebookRound, ],
    [ 'gift', GiftAction, ],
    [ 'googleplus', GooglePlus, ],
    [ 'mail', Mail, ],
    [ 'mailalert', MailAlert, ],
    [ 'messenger', Messenger, ],
    [ 'print', Print, ],
    [ 'reddit', Reddit, ],
    [ 'readinglist', ReadingList, ],
    [ 'save', Save, ],
    [ 'twitter', Twitter, ],
    [ 'telegram', Telegram, ],
    [ 'whatsapp', Whatsapp, ],
    [ 'zen', Zen, ],
  ]);

  return actions.get(iconName.toLowerCase());
};

export default getAction;
